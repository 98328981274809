import { registerLocaleData } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeEn);
registerLocaleData(localeDe);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
