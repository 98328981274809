import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { IconModule } from '../icon/icon.module';
import { CardModule } from '../card/card.module';
import { DialogService } from './dialog.service';
import { HeadingModule } from '../heading/heading.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [DialogComponent],
  imports: [CommonModule, HeadingModule, IconModule, CardModule, ButtonModule],
  exports: [DialogComponent],
  providers: [DialogService],
})
export class DialogModule {}
