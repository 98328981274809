import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModifiableBasketComponent } from './modifiable-basket.component';
import { ButtonModule } from '../button/button.module';
import { HeadingModule } from '../heading/heading.module';
import { CounterModule } from '../counter/counter.module';

@NgModule({
  declarations: [ModifiableBasketComponent],
  imports: [CommonModule, ButtonModule, HeadingModule, CounterModule],
  exports: [ModifiableBasketComponent],
})
export class ModifiableBasketModule {}
