<router-outlet></router-outlet>
<div class="legal">
  <div class="legal-copyright">
    &copy; 2024 WELLEXT Frankfurt GmbH & Co. KG.<br />Preise verstehen sich inkl. MwSt.
  </div>
  <div class="legal-resources">
    <a class="legal-resource" href="https://www.wellext.de/legal/agb">AGB</a>
    <a class="legal-resource" href="https://www.wellext.de/legal/datenschutz">Datenschutz</a>
    <a class="legal-resource" href="https://www.wellext.de/legal/impressum">Impressum</a>
  </div>
</div>
<ng-template wlxDialogHost></ng-template>
