import { NgModule } from '@angular/core';
import { HeadingModule } from './components/heading/heading.module';
import { ProgressionModule } from './components/progression/progression.module';
import { IconModule } from './components/icon/icon.module';
import { PackageModule } from './components/package/package.module';
import { ButtonModule } from './components/button/button.module';
import { ChipsModule } from './components/chips/chips.module';
import { SelectModule } from './components/select/select.module';
import { InputModule } from './components/input/input.module';
import { CheckboxModule } from './components/checkbox/checkbox.module';
import { CardModule } from './components/card/card.module';
import { ExtraModule } from './components/extra/extra.module';
import { CalendarModule } from './components/calendar/calendar.module';
import { CounterModule } from './components/counter/counter.module';
import { FlareModule } from './components/flare/flare.module';
import { DialogModule } from './components/dialog/dialog.module';
import { BasketModule } from './components/basket/basket.module';
import { ModifiableBasketModule } from './components/modifiable-basket/modifiable-basket.module';

@NgModule({
  exports: [
    HeadingModule,
    ProgressionModule,
    IconModule,
    PackageModule,
    ButtonModule,
    ChipsModule,
    SelectModule,
    CalendarModule,
    InputModule,
    CheckboxModule,
    CardModule,
    ExtraModule,
    CounterModule,
    FlareModule,
    DialogModule,
    BasketModule,
    ModifiableBasketModule,
  ],
})
export class WellextKitModule {}
