import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'wlx-booking-portal-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    const lockId = localStorage.getItem('lockId') || crypto.randomUUID();
    localStorage.setItem('lockId', lockId);
  }
}
